function SmartFormField(props) {
    let fieldType=props.fieldType;
    let isrequired = props.required;
    let placeholder = props.placeholder;
    let label = props.label;

    let inputData = props.InputData;
    let setInputData = props.getInputData;

    function setData(data) {
        setInputData(data);
    }
    return (
        <smart-form-field>
            <div className={(fieldType)?"field-label lg-field":"field-label"}>
                <label className="input-smart-label">{label}</label>
                {
                    (isrequired) ?
                    <span className="label-required">*</span>
                    :""
                }
            </div>
            <div className={(fieldType)?"input-field lg-field":"input-field"}>
                {
                    (isrequired) ?
                        <input className={(inputData==="")?"input-smart-cell smart-red-border":"input-smart-cell smart-green-border"} required="required" type="text" placeholder={placeholder} value={inputData} onChange={(e) => setData(e.target.value)} id={label.replace(/\s/g, "")} autoComplete="off" />
                        :
                        <input className={(inputData==="")?"input-smart-cell":"input-smart-cell smart-green-border"} type="text" placeholder={placeholder} value={inputData} onChange={(e) => setData(e.target.value)} id={label.replace(/\s/g, "")} autoComplete="off" />

                }
            </div>
        </smart-form-field>
    )
}
export default SmartFormField;
import '../../css/popup.css';

function ConfirmationPopup(props) {

    let popup = props.Popup;
    let setPopup = props.SetPopup;
    let popupbody = props.PopupBody;
    let popuphader = props.PopupHader;
    let popupConfirm = props.PopupConfirm;

    let yesPopup = props.PopupDataConfirm;
    let noPopup = props.PopupDataCancel;

    function setPopupWindow(data) {
        if (setPopup !== undefined) {
            setPopup(data);
        } else {
            popup = data;
        }
    }

    return (
        <div className={(popup === true) ? "mypopup popupfade popupshow" : "mypopup popupfade"}>
            <div className={"mypopup-dialog"}>
                <div className="mypopup-content">
                    <div className="mypopup-header">
                        {popuphader}
                    </div>
                    <div className="mypopup-body">
                        {popupbody}
                    </div>
                    <div className="mypopup-footer">
                        <div className="my-button my-button-sucess" onClick={() => popupConfirm()}> {yesPopup} </div>
                        <div className="my-button my-button-error" onClick={() => setPopupWindow(false)}> {noPopup} </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default ConfirmationPopup;
import { useState } from "react";

function SmartFormSelect(props) {
    let fieldType = props.fieldType;
    let isrequired = props.required;
    let placeholder = props.placeholder;
    let label = props.label;

    let inputData = props.InputData;
    let setInputData = props.getInputData;
    let options = props.setOptions;
    let reverse = props.reverseMenu;
    let searchOption = props.searchOption;

    let fullData = props.fullData;
    let setfullData = props.setfullData;

    const [menu, setMenu] = useState(false);
    const [search, setSearch] = useState("");

    function setData(data) {
        setInputData(data);
        setMenu(false);
        setSearch("");
        if (fullData !== undefined) {
            let originalindex = options.indexOf(data);
            setfullData(fullData[originalindex]);
        }
    }

    function dataSearchInput(data) {
        setSearch(data);
    }

    function getAllOptions() {
        if (search !== "") {
            return options.filter((filter) => filter.toLowerCase().includes(search.toLowerCase()));
        } else {
            return options;
        }
    }

    return (
        <smart-form-field>
            {
                (label !== "") ?
                    <div className={(fieldType) ? "field-label lg-field" : "field-label"}>
                        <label className="input-smart-label">{label}</label>
                        {
                            (isrequired) ?
                                <span className="label-required">*</span>
                                : ""
                        }
                    </div>
                    : ""
            }
            <div className={(fieldType) ? "input-field lg-field" : "input-field"}>
                <div className="mydropdown mydrop">
                    {
                        (isrequired) ?
                            <div className={(inputData === "") ? "drop-input-cont smart-red-border" : "drop-input-cont smart-green-border"} onClick={() => setMenu(!menu)}>
                                <input type="text" placeholder={placeholder} value={inputData} required="required" className="mydrop" readOnly />
                                {
                                    (menu) ?
                                        <span className="drop-arrow">
                                            <i className="fa-solid fa-angle-up"></i>
                                        </span>
                                        :
                                        <span className="drop-arrow">
                                            <i className="fa-solid fa-angle-down"></i>
                                        </span>
                                }
                            </div>
                            :
                            <div className={(inputData === "") ? "drop-input-cont" : "drop-input-cont smart-green-border"} onClick={() => setMenu(!menu)}>
                                <input type="text" placeholder={placeholder} value={inputData} className="mydrop" readOnly />
                                {
                                    (menu) ?
                                        <span className="drop-arrow">
                                            <i className="fa-solid fa-angle-up"></i>
                                        </span>
                                        :
                                        <span className="drop-arrow">
                                            <i className="fa-solid fa-angle-down"></i>
                                        </span>
                                }
                            </div>
                    }
                    <div className={(menu) ? (reverse) ? "dropdown_content show reverse" : "dropdown_content show" : (reverse) ? "dropdown_content reverse" : "dropdown_content"} id="department">
                        {
                            (searchOption) ?
                                <div className="dropdown_search">
                                    <input type="text" placeholder={"Enter the Search..."} onChange={(e) => dataSearchInput(e.target.value)} value={search} />
                                </div>
                                : ""
                        }
                        <div className="dropdown_list">
                            {
                                (getAllOptions().length !== 0) ?
                                    getAllOptions().map((option, index) =>
                                        (option === inputData) ?
                                            <div className="dropdown_item selected" key={index} onClick={() => setData(option)}>{option}<i className="fas fa-check"></i></div>
                                            :
                                            <div className="dropdown_item" key={index} onClick={() => setData(option)}>{option}<i className="fas fa-check"></i></div>
                                    ) : <div className="dropdown_item">No Options<i className="fas fa-check"></i></div>
                            }
                        </div>
                    </div>
                </div>

            </div>
        </smart-form-field>
    )
}
export default SmartFormSelect;
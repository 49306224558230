import { Helmet, HelmetProvider } from "react-helmet-async";
import '../../css/mainlayout.css';
import '../../css/field.css';
import { useState } from "react";
import Header from "../layout/Header";
import SideMenu from "../layout/SideMenu";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../Loading";
import Popup from "../utils/Popup";
import SmartFormField from "../utils/SmartFormField";
import SmartFormSelect from "../utils/SmartFormSelect";
import { useNavigate } from "react-router-dom";
import { AddClients } from "../../redux/ClientRedux";


function AddClient() {

    const [mobNav, setMobNav] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { clientLoading } = useSelector((state) => state.Client);

    const [errorPopup, setErrorPopup] = useState(false);
    let [errorMsg, setErrorMsg] = useState("");
    let [errorHeader, setErrorHeader] = useState("");
    let [errorType, setErrorType] = useState("");

    const [name, setName] = useState("");
    const [nickname, setNickName] = useState("");
    const [mobile, setMobile] = useState("");
    const [wmobile, setWMobile] = useState("");
    const [aline1, setALine1] = useState("");
    const [aline2, setALine2] = useState("");
    const [zipcode, setZipcode] = useState("");
    const [location, setLocation] = useState("");
    const [delivery, setDelivery] = useState("No");

    function createClient(event) {
        event.preventDefault();
        let cData = { name, nickname, mobile, wmobile, aline1, aline2, zipcode, location, delivery };
        dispatch(AddClients(cData)).then((result) => {
            if (result.payload !== undefined) {
                if (result.payload.error === "") {
                    navigate("/clients");
                }
            } else {
                setErrorMsg(result.error.message);
                setErrorHeader("Server Error");
                setErrorPopup(true);
                setErrorType("mypopup-error");
            }
        });
    }

    function formReset() {
        document.getElementById("addClient").reset();
        setNickName("");
        setMobile("");
        setWMobile("");
        setALine1("");
        setALine2("");
        setZipcode("");
        setLocation("");
        setDelivery("No");
    }

    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <title>Add Client | Smart OS - Smart Shop</title>
                </Helmet>
            </HelmetProvider>
            <Header onMobNav={setMobNav} valueMobNav={mobNav} />
            <div className="total-body">
                <SideMenu Options="Clients" valueMobNav={mobNav} />
                <div className="right-total-body">
                    <div className="project-title">Add New <span>Client</span></div>
                    <div className="myRow">
                        <form onSubmit={(event) => createClient(event)} id="addClient">
                            <div className="myRow create-form">
                                <div className="col-md-6">
                                    <SmartFormField getInputData={setName} InputData={name} label="Client Name" required={true} placeholder="Enter Client Name" fieldType={true} />
                                </div>
                                <div className="col-md-6">
                                    <SmartFormField getInputData={setNickName} InputData={nickname} label="Client Nick Name" required={false} placeholder="Enter Clicnt Nick Name" fieldType={true} />
                                </div>
                            </div>
                            <div className="myRow create-form">
                                <div className="col-md-6">
                                    <SmartFormField getInputData={setMobile} InputData={mobile} label="Mobile" required={true} placeholder="Enter Mobile Number" fieldType={false} />
                                </div>
                                <div className="col-md-6">
                                    <SmartFormField getInputData={setWMobile} InputData={wmobile} label="WhatsApp" required={false} placeholder="Enter WhatsApp Number" fieldType={false} />
                                </div>

                            </div>
                            <div className="myRow create-form">
                                <div className="col-md-6">
                                    <SmartFormField getInputData={setALine1} InputData={aline1} label="Address Line 1" required={true} placeholder="Enter Address Line 1" fieldType={false} />
                                </div>
                                <div className="col-md-6">
                                    <SmartFormField getInputData={setALine2} InputData={aline2} label="Address Line 2" required={false} placeholder="Enter Address Line 2" fieldType={false} />
                                </div>
                            </div>
                            <div className="myRow create-form">
                                <div className="col-md-4">
                                    <SmartFormField getInputData={setZipcode} InputData={zipcode} label="Zip Code" required={true} placeholder="Enter Zipcode" fieldType={true} />
                                </div>
                                <div className="col-md-4">
                                    <SmartFormField getInputData={setLocation} InputData={location} label="Map Location" required={false} placeholder="Enter Map Location" fieldType={true} />
                                </div>
                                <div className="col-md-4">
                                    <SmartFormSelect getInputData={setDelivery} InputData={delivery} searchOption={false} label="is Delevery ?" required={true} placeholder="Select Delevery" fieldType={true} setOptions={["Yes", "No"]} reverseMenu={true} />
                                </div>
                            </div>
                            <div className="mob-padding">

                            </div>
                            <div className="myRow create-form-toolbar">
                                <button type="button" className="my-button bg-lightred" onClick={() => formReset()}><i className="fa-solid fa-arrow-rotate-right"></i> Reset</button>
                                <button type="submit" className="my-button bg-lightgreen"><i className="fa-solid fa-plus"></i> Create</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <Popup Popup={errorPopup} PopupBody={errorMsg} PopupHader={errorHeader} PopupType={errorType} SetPopup={setErrorPopup} />
            {
                clientLoading ? <Loading /> : ""
            }
        </>
    );
}
export default AddClient;
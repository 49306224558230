import { Link, useNavigate } from "react-router-dom";
import ConfirmationPopup from "../utils/ConfirmationPopup";
import { useState } from "react";

function SideMenu(props) {

    let options = props.Options;

    let valueMobNav = props.valueMobNav;

    const [logoutpopup,SetLogoutPopup]=useState(false);

    const navigate = useNavigate();

    const logout = () => {
        localStorage.clear();
        navigate("/login");
    }


    function makeMainmenu(myOption, mlink, icon) {
        return options === myOption ? <Link to={mlink}><div className="menu-item selected">{icon} {myOption}</div></Link>
            : <Link to={mlink}><div className="menu-item">{icon} {myOption}</div></Link>
    }

    return (
        <>
            <div className={valueMobNav ? "left-total-body mob-nav-display" : "left-total-body"}>
                <div className="fullmenu">
                    <div className="sidemenu-itemlist">                        
                        {makeMainmenu("Dashboard", "/dashboard", <i className="fa-solid fa-chart-line"></i>)}
                        {makeMainmenu("Bills", "/bills", <i className="fa-solid fa-receipt"></i>)}
                        {makeMainmenu("Products", "/products", <i className="fa-solid fa-database"></i>)}
                        {makeMainmenu("Clients", "/clients", <i className="fa-regular fa-address-book"></i>)}
                        {makeMainmenu("Profile", "/profile", <i className="fas fa-user-alt"></i>)}
                        <div className="menu-item" onClick={()=>SetLogoutPopup(true)}><i className="fas fa-sign-out-alt"></i> Logout</div>
                    </div>
                </div>
            </div >
            <ConfirmationPopup Popup={logoutpopup} PopupBody={"Are you Want to Logout?"} PopupHader={"Information"} PopupConfirm={logout} SetPopup={SetLogoutPopup} PopupDataConfirm={"Yes"} PopupDataCancel={"No"} />
        </>
    );
}
export default SideMenu;
import '../../css/popup.css';

function Popup(props) {

    let popup = props.Popup;
    let setPopup = props.SetPopup;
    let popupbody = props.PopupBody;
    let popuphader = props.PopupHader;
    let popuptype = props.PopupType;

    function setPopupWindow(data) {
        if (setPopup !== undefined) {
            setPopup(data);
        } else {
            popup = data;
        }
    }

    return (
        <div className={(popup === true) ? "mypopup popupfade popupshow" : "mypopup popupfade"}>
            <div className={"mypopup-dialog " + popuptype}>
                <div className="mypopup-content">
                    <div className="mypopup-header">
                        {popuphader}
                    </div>
                    <div className="mypopup-body">
                        {popupbody}
                    </div>
                    <div className="mypopup-footer">
                        {
                            (popuptype === "mypopup-sucess") ?
                                <div className="my-button" onClick={() => setPopupWindow(false)}> OK </div>
                                :
                                <div className="my-button" onClick={() => setPopupWindow(false)}> Close </div>
                        }

                    </div>
                </div>
            </div>
        </div>
    );
}
export default Popup;
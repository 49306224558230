import { configureStore } from "@reduxjs/toolkit";
import productReducer from "../redux/ProductRedux";
import clientReducer from "../redux/ClientRedux";


const store = configureStore({
    reducer: {
        Product: productReducer,
        Client: clientReducer,
    }
});

export default store;
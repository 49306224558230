import { Helmet, HelmetProvider } from "react-helmet-async";
import '../../css/mainlayout.css';
import '../../css/field.css';
import { useCallback, useEffect, useState } from "react";
import Header from "../layout/Header";
import SideMenu from "../layout/SideMenu";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../Loading";
import Popup from "../utils/Popup";
import { Link, useNavigate, useParams } from "react-router-dom";
import { DeleteClient, GetClient } from "../../redux/ClientRedux";


function ViewClients() {

    const [mobNav, setMobNav] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { clientLoading } = useSelector((state) => state.Client);

    const [errorPopup, setErrorPopup] = useState(false);
    let [errorMsg, setErrorMsg] = useState("");
    let [errorHeader, setErrorHeader] = useState("");
    let [errorType, setErrorType] = useState("");


    const [name, setName] = useState("");
    const [nickname, setNickName] = useState("");
    const [mobile, setMobile] = useState("");
    const [wmobile, setWMobile] = useState("");
    const [aline1, setALine1] = useState("");
    const [aline2, setALine2] = useState("");
    const [zipcode, setZipcode] = useState("");
    const [location, setLocation] = useState("");
    const [delivery, setDelivery] = useState("");
    const [cid, setCID] = useState("");

    let { clientID } = useParams();

    const getClientData = useCallback(() => {
        dispatch(GetClient(clientID)).then((result) => {
            if (result.meta.requestStatus === "fulfilled") {
                if (result.payload !== undefined) {
                    if (result.payload.error === "") {
                        let dataTest = result.payload.data;
                        setName(dataTest.name);
                        setNickName(dataTest.nickname);
                        setMobile(dataTest.mobile);
                        setWMobile(dataTest.wmobile);
                        setALine1(dataTest.aline1);
                        setALine2(dataTest.aline2);
                        setZipcode(dataTest.zipcode);                        
                        setLocation(dataTest.location);
                        setDelivery(dataTest.delivery);
                        setCID(dataTest.id);
                    } else {
                        let msg = result.payload.error;
                        let header = "Error";
                        setErrorMsg(msg);
                        setErrorHeader(header);
                        setErrorType("mypopup-error");
                        setErrorPopup(true);
                    }
                } else {
                    setErrorMsg(result.error.message);
                    setErrorHeader("Server Error");
                    setErrorPopup(true);
                    setErrorType("mypopup-error");
                }
            }
            else if (result.meta.requestStatus === "rejected") {
                setErrorMsg(result.error.message);
                setErrorHeader("Server Error");
                setErrorPopup(true);
                setErrorType("mypopup-error");
            }
        });
    }, [dispatch]);

    function deleteData() {
        dispatch(DeleteClient(cid)).then((result) => {
            if (result.meta.requestStatus === "fulfilled") {
                if (result.payload !== undefined) {
                    if (result.payload.error === "") {
                        navigate("/clients");
                    } else {
                        let msg = result.payload.error;
                        let header = "Error";
                        setErrorMsg(msg);
                        setErrorHeader(header);
                        setErrorType("mypopup-error");
                        setErrorPopup(true);
                    }
                } else {
                    setErrorMsg(result.error.message);
                    setErrorHeader("Server Error");
                    setErrorPopup(true);
                    setErrorType("mypopup-error");
                }
            }
            else if (result.meta.requestStatus === "rejected") {
                setErrorMsg(result.error.message);
                setErrorHeader("Server Error");
                setErrorPopup(true);
                setErrorType("mypopup-error");
            }
        });
    }

    useEffect(() => {
        getClientData();
    }, [getClientData]);

    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <title>View Client | Smart OS - Smart Shop</title>
                </Helmet>
            </HelmetProvider>
            <Header onMobNav={setMobNav} valueMobNav={mobNav} />
            <div className="total-body">
                <SideMenu Options="Products" valueMobNav={mobNav} />
                <div className="right-total-body">
                    <div className="project-title">View Client - <span>{name}</span></div>
                    <div className="myRow">

                        <div className="myRow create-form">
                            <div className="col-md-6">
                                <div className="title-label">Client Name</div>
                                <div className="details-text">{name}</div>
                            </div>
                            <div className="col-md-6">
                                <div className="title-label">Nick Name</div>
                                <div className="details-text">{nickname}</div>
                            </div>
                        </div>
                        <div className="myRow create-form">
                            <div className="col-md-6">
                                <div className="title-label">Mobile</div>
                                <div className="details-text">{mobile}</div>
                            </div>
                            <div className="col-md-6">
                                <div className="title-label">WhatsApp</div>
                                <div className="details-text">{wmobile}</div>
                            </div>                           
                        </div>
                        <div className="myRow create-form">
                            <div className="col-md-6">
                                <div className="title-label">Address Line 1</div>
                                <div className="details-text">{aline1}</div>
                            </div>
                            <div className="col-md-6">
                                <div className="title-label">Address Line 2</div>
                                <div className="details-text">{aline2}</div>
                            </div>
                        </div>
                        <div className="myRow create-form">
                            <div className="col-md-4">
                                <div className="title-label">Zip Code</div>
                                <div className="details-text">{zipcode}</div>
                            </div>
                            <div className="col-md-4">
                                <div className="title-label">Map Location</div>
                                <div className="details-text">{location}</div>
                            </div>
                            <div className="col-md-4">
                                <div className="title-label">is Delevery ?</div>
                                <div className="details-text">{delivery}</div>
                            </div>
                        </div>
                        <div className="mob-padding">

                        </div>
                        <div className="myRow create-form-toolbar">
                            <button className="my-button bg-lightred" onClick={() => deleteData()}><i className="fa-solid fa-trash"></i> Delete</button>
                            <Link className="my-button bg-lightblue" to={"/clients/edit/" + clientID}><i className="fa-solid fa-pen"></i> Edit</Link>
                        </div>

                    </div>
                </div>
            </div>

            <Popup Popup={errorPopup} PopupBody={errorMsg} PopupHader={errorHeader} PopupType={errorType} SetPopup={setErrorPopup} />
            {
                clientLoading ? <Loading /> : ""
            }
        </>
    );
}
export default ViewClients;
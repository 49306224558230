import { Suspense} from "react";
import Loading from "./Loading";
import '../css/mainlayout.css';

function ProtectedRoute(props) {
    let Component = props.components;    
    return (
        <Suspense fallback={<Loading />}>
            <Component />
        </Suspense>
    )
}
export default ProtectedRoute;
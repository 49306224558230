import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const GetAllProduct = createAsyncThunk(
    'Product/Getall',
    async () => {
        let result = await fetch(process.env.REACT_APP_API_URL + "api/product/getall", {
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            }
        })
        result = await result.json();
        return result;
    });
export const GetProduct = createAsyncThunk(
    'Product/Get',
    async (dataid) => {
        let result = await fetch(process.env.REACT_APP_API_URL + "api/product/get/" + dataid, {
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            }
        })
        result = await result.json();
        return result;
    });
export const AddProduct = createAsyncThunk(
    'Product/Add',
    async (data) => {
        let result = await fetch(process.env.REACT_APP_API_URL + "api/product/add", {
            method: "POST",
            body: data
        })
        result = await result.json();
        return result;
    });
export const UpdateProduct = createAsyncThunk(
    'Product/Update',
    async (data) => {
        let result = await fetch(process.env.REACT_APP_API_URL + "api/product/update", {
            method: "POST",
            body: data
        })
        result = await result.json();
        return result;
    });
export const DeleteProduct = createAsyncThunk(
    'Product/Delete',
    async (dataid) => {
        let result = await fetch(process.env.REACT_APP_API_URL + "api/product/delete/" + dataid, {
            method: "DELETE"
        })
        result = await result.json();
        return result;
    });
const ProductSlice = createSlice({
    name: 'product',
    initialState: {
        productLoading: false,
        product: null,
        productError: null
    },
    extraReducers: (builder) => {
        builder
            .addCase(GetAllProduct.pending, (state) => {
                state.productLoading = true;
                state.product = null;
                state.productError = null;
            })
            .addCase(GetAllProduct.fulfilled, (state, action) => {
                state.productLoading = false;
                state.product = action.payload.data;
                state.productError = null;
            })
            .addCase(GetAllProduct.rejected, (state, action) => {
                state.productLoading = false;
                state.product = null;
                if (action.error !== undefined) {
                    if (action.error.message) {
                        state.productError = action.error.message;
                    }
                }
            })
            .addCase(GetProduct.pending, (state) => {
                state.productLoading = true;
                state.product = null;
                state.productError = null;
            })
            .addCase(GetProduct.fulfilled, (state, action) => {
                state.productLoading = false;
                state.product = action.payload.data;
                state.productError = null;
            })
            .addCase(GetProduct.rejected, (state, action) => {
                state.productLoading = false;
                state.product = null;
                if (action.error !== undefined) {
                    if (action.error.message) {
                        state.productError = action.error.message;
                    }
                }
            })
            .addCase(AddProduct.pending, (state) => {
                state.productLoading = true;
                state.product = null;
                state.productError = null;
            })
            .addCase(AddProduct.fulfilled, (state, action) => {
                state.productLoading = false;
                state.product = action.payload.data;
                state.productError = null;
            })
            .addCase(AddProduct.rejected, (state, action) => {
                state.productLoading = false;
                state.product = null;
                if (action.error !== undefined) {
                    if (action.error.message) {
                        state.productError = action.error.message;
                    }
                }
            })
            .addCase(UpdateProduct.pending, (state) => {
                state.productLoading = true;
                state.product = null;
                state.productError = null;
            })
            .addCase(UpdateProduct.fulfilled, (state, action) => {
                state.productLoading = false;
                state.product = action.payload.data;
                state.productError = null;
            })
            .addCase(UpdateProduct.rejected, (state, action) => {
                state.productLoading = false;
                state.product = null;
                if (action.error !== undefined) {
                    if (action.error.message) {
                        state.productError = action.error.message;
                    }
                }
            })
            .addCase(DeleteProduct.pending, (state) => {
                state.productLoading = true;
                state.product = null;
                state.productError = null;
            })
            .addCase(DeleteProduct.fulfilled, (state, action) => {
                state.productLoading = false;
                state.product = action.payload.data;
                state.productError = null;
            })
            .addCase(DeleteProduct.rejected, (state, action) => {
                state.productLoading = false;
                state.product = null;
                if (action.error !== undefined) {
                    if (action.error.message) {
                        state.productError = action.error.message;
                    }
                }
            })
    }
});
export default ProductSlice.reducer;
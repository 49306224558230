import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Suspense, lazy } from 'react';
import Loading from './components/Loading';
import ProtectedRoute from './components/ProtectedRoute';
import AddClient from './components/Contacts/AddClient';
import ViewClients from './components/Contacts/ViewClients';
import EditClients from './components/Contacts/EditClients';

const Templates = lazy(() => import('./components/Dashboard/Template'));
const Dashboard = lazy(() => import('./components/Dashboard/Dashboard'));
const Bills = lazy(() => import('./components/Bills/Bills'));
const Products = lazy(() => import('./components/Products/Products'));
const Contacts = lazy(() => import('./components/Contacts/Contacts'));
const Profile = lazy(() => import('./components/Profile/Profile'));
const PageNotFound = lazy(() => import('./components/layout/PageNotFound'));
const AddProducts = lazy(() => import('./components/Products/AddProducts'));
const EditProducts = lazy(() => import('./components/Products/EditProducts'));
const ViewProducts = lazy(() => import('./components/Products/ViewProducts'));

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={
            <Suspense fallback={<Loading />}>
              <Dashboard />
            </Suspense>} 
            />          
          <Route path="*" element={<Suspense fallback={<Loading />}>
            <PageNotFound />
          </Suspense>} />
          <Route path='/dashboard' element={<ProtectedRoute components={Dashboard} />} />
          <Route path='/bills' element={<ProtectedRoute components={Bills} />} />
          <Route path='/products' element={<ProtectedRoute components={Products} />} />
          <Route path='/products/add' element={<ProtectedRoute components={AddProducts} />} />
          <Route path='/products/edit/:productID' element={<ProtectedRoute components={EditProducts} />} />
          <Route path='/products/view/:productID' element={<ProtectedRoute components={ViewProducts} />} />

          <Route path='/clients' element={<ProtectedRoute components={Contacts} />} />
          <Route path='/clients/add' element={<ProtectedRoute components={AddClient} />} />
          <Route path='/clients/edit/:clientID' element={<ProtectedRoute components={EditClients} />} />
          <Route path='/clients/view/:clientID' element={<ProtectedRoute components={ViewClients} />} />

          <Route path='/profile' element={<ProtectedRoute components={Profile} />} />
          
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;

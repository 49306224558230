import { Helmet, HelmetProvider } from "react-helmet-async";
import '../../css/mainlayout.css';
import '../../css/field.css';
import { useCallback, useEffect, useState } from "react";
import Header from "../layout/Header";
import SideMenu from "../layout/SideMenu";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../Loading";
import Popup from "../utils/Popup";
import SmartFormField from "../utils/SmartFormField";
import SmartFormSelect from "../utils/SmartFormSelect";
import { Link, useNavigate, useParams } from "react-router-dom";
import { GetClient, UpdateClient } from "../../redux/ClientRedux";


function EditClients() {

    const [mobNav, setMobNav] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { clientLoading } = useSelector((state) => state.Client);

    const [errorPopup, setErrorPopup] = useState(false);
    let [errorMsg, setErrorMsg] = useState("");
    let [errorHeader, setErrorHeader] = useState("");
    let [errorType, setErrorType] = useState("");


    const [name, setName] = useState("");
    const [nickname, setNickName] = useState("");
    const [mobile, setMobile] = useState("");
    const [wmobile, setWMobile] = useState("");
    const [aline1, setALine1] = useState("");
    const [aline2, setALine2] = useState("");
    const [zipcode, setZipcode] = useState("");
    const [location, setLocation] = useState("");
    const [delivery, setDelivery] = useState("");
    const [custId, setCID] = useState("");

    let { clientID } = useParams();

    function modifyClient(event) {
        event.preventDefault();
        let cData = { name, nickname, mobile, wmobile, aline1, aline2, zipcode, location, delivery,custId };
        dispatch(UpdateClient(cData)).then((result) => {
            if (result.payload !== undefined) {
                if (result.payload.error === "") {
                    navigate("/clients");
                }
            } else {
                setErrorMsg(result.error.message);
                setErrorHeader("Server Error");
                setErrorPopup(true);
                setErrorType("mypopup-error");
            }
        });
    }
    
    const getClientData = useCallback(() => {
        dispatch(GetClient(clientID)).then((result) => {
            if (result.meta.requestStatus === "fulfilled") {
                if (result.payload !== undefined) {
                    if (result.payload.error === "") {
                        let dataTest = result.payload.data;
                        setName(dataTest.name);
                        setNickName(dataTest.nickname);
                        setMobile(dataTest.mobile);
                        setWMobile(dataTest.wmobile);
                        setALine1(dataTest.aline1);
                        setALine2(dataTest.aline2);
                        setZipcode(dataTest.zipcode);                        
                        setLocation(dataTest.location);
                        setDelivery(dataTest.delivery);
                        setCID(dataTest.id);
                    } else {
                        let msg = result.payload.error;
                        let header = "Error";
                        setErrorMsg(msg);
                        setErrorHeader(header);
                        setErrorType("mypopup-error");
                        setErrorPopup(true);
                    }
                } else {
                    setErrorMsg(result.error.message);
                    setErrorHeader("Server Error");
                    setErrorPopup(true);
                    setErrorType("mypopup-error");
                }
            }
            else if (result.meta.requestStatus === "rejected") {
                setErrorMsg(result.error.message);
                setErrorHeader("Server Error");
                setErrorPopup(true);
                setErrorType("mypopup-error");
            }
        });
    }, [dispatch]);

    useEffect(() => {
        getClientData();
    }, [getClientData]);

    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <title>Edit Client | Smart OS - Smart Shop</title>
                </Helmet>
            </HelmetProvider>
            <Header onMobNav={setMobNav} valueMobNav={mobNav} />
            <div className="total-body">
                <SideMenu Options="Products" valueMobNav={mobNav} />
                <div className="right-total-body">
                    <div className="project-title">Edit Client - <span>{name}</span></div>
                    <div className="myRow">
                        <form onSubmit={(event) => modifyClient(event)} id="editclient">
                        <div className="myRow create-form">
                                <div className="col-md-6">
                                    <SmartFormField getInputData={setName} InputData={name} label="Client Name" required={true} placeholder="Enter Client Name" fieldType={true} />
                                </div>
                                <div className="col-md-6">
                                    <SmartFormField getInputData={setNickName} InputData={nickname} label="Client Nick Name" required={false} placeholder="Enter Clicnt Nick Name" fieldType={true} />
                                </div>
                            </div>
                            <div className="myRow create-form">
                                <div className="col-md-6">
                                    <SmartFormField getInputData={setMobile} InputData={mobile} label="Mobile" required={true} placeholder="Enter Mobile Number" fieldType={false} />
                                </div>
                                <div className="col-md-6">
                                    <SmartFormField getInputData={setWMobile} InputData={wmobile} label="WhatsApp" required={false} placeholder="Enter WhatsApp Number" fieldType={false} />
                                </div>

                            </div>
                            <div className="myRow create-form">
                                <div className="col-md-6">
                                    <SmartFormField getInputData={setALine1} InputData={aline1} label="Address Line 1" required={true} placeholder="Enter Address Line 1" fieldType={false} />
                                </div>
                                <div className="col-md-6">
                                    <SmartFormField getInputData={setALine2} InputData={aline2} label="Address Line 2" required={false} placeholder="Enter Address Line 2" fieldType={false} />
                                </div>
                            </div>
                            <div className="myRow create-form">
                                <div className="col-md-4">
                                    <SmartFormField getInputData={setZipcode} InputData={zipcode} label="Zip Code" required={true} placeholder="Enter Zipcode" fieldType={true} />
                                </div>
                                <div className="col-md-4">
                                    <SmartFormField getInputData={setLocation} InputData={location} label="Map Location" required={false} placeholder="Enter Map Location" fieldType={true} />
                                </div>
                                <div className="col-md-4">
                                    <SmartFormSelect getInputData={setDelivery} InputData={delivery} searchOption={false} label="is Delevery ?" required={true} placeholder="Select Delevery" fieldType={true} setOptions={["Yes", "No"]} reverseMenu={true} />
                                </div>
                            </div>
                            <div className="mob-padding">

                            </div>
                            <div className="myRow create-form-toolbar">
                                <Link className="my-button bg-yellow" to={"/clients/view/" + clientID}><i className="fa-solid fa-chevron-left"></i> Cancel</Link>
                                <button type="submit" className="my-button bg-lightgreen"><i className="fa-regular fa-floppy-disk"></i> Save</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <Popup Popup={errorPopup} PopupBody={errorMsg} PopupHader={errorHeader} PopupType={errorType} SetPopup={setErrorPopup} />
            
            {
                clientLoading ? <Loading /> : ""
            }
        </>
    );
}
export default EditClients;
import { Link, useNavigate } from 'react-router-dom';
import adminprofile from '../../img/client-men.png';
import { useState } from 'react';
import packageJson from '../../../package.json'

function Header(props) {
    let valueMobNav = props.valueMobNav;
    let onMobNav = props.onMobNav;

    const version = packageJson.version;

    const [menupopup, setMenuPopup] = useState(false);

    const navigate=useNavigate();
    const logout = () => {
        localStorage.clear();
        navigate("/login");
    }

    function toggleNav() {
        onMobNav(!valueMobNav);
    }
    function getGreet() {
        const currTime = new Date();
        let greet = "";
        if (currTime.getHours() < 6) {
            greet = "Night";
        } else if (currTime.getHours() < 12) {
            greet = "Morning";
        } else if (currTime.getHours() < 17) {
            greet = "AfterNoon";
        } else if (currTime.getHours() < 19) {
            greet = "Evening";
        } else {
            greet = "Night";
        }
        return greet;
    };
    return (
        <div className="topNav">
            <div className="mob-nav-toggle" onClick={() => toggleNav()}>
                <i className="fas fa-bars"></i>
            </div>
            <div className="logo-title">
                <span>Smart</span> OS
            </div>

            <div className="right-part">
                <div className="optionItem" onClick={() =>setMenuPopup(!menupopup)}>
                    <img src={adminprofile} alt="Profile Pic" className="profileimg" />
                </div>
            </div>
            <div className="greetType">
                <span className='well'>Welcome,</span> Owner<br />
                Good <span>{getGreet()}</span>
            </div>
            <div className={menupopup?"popupcontainter popupshow":"popupcontainter"}>
                <div className='popupbody'>
                    Owner<br/>
                    <div className='version-data'><span className='softwear-name'>Smart <span>OS</span></span> - v <span>{version}</span></div>
                </div>
                <div className='popupfooter'>
                    <Link to="/profile" className='popupbut' onClick={()=>setMenuPopup(!menupopup)}>Profile</Link>
                    <div className='popupbut' onClick={logout}>Logout</div>
                </div>
            </div>
        </div>
    );
}
export default Header;
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const GetAllClient = createAsyncThunk(
    'Client/Getall',
    async () => {
        let result = await fetch(process.env.REACT_APP_API_URL + "api/client/getall", {
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            }
        })
        result = await result.json();
        return result;
    });
export const GetClient = createAsyncThunk(
    'Client/Get',
    async (dataid) => {
        let result = await fetch(process.env.REACT_APP_API_URL + "api/client/get/" + dataid, {
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            }
        })
        result = await result.json();
        return result;
    });
export const AddClients = createAsyncThunk(
    'Client/Add',
    async (data) => {
        let result = await fetch(process.env.REACT_APP_API_URL + "api/client/add", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            body: JSON.stringify(data)
        })
        result = await result.json();
        return result;
    });
export const UpdateClient = createAsyncThunk(
    'Client/Update',
    async (data) => {
        let result = await fetch(process.env.REACT_APP_API_URL + "api/client/update", {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            body: JSON.stringify(data)
        })
        result = await result.json();
        return result;
    });
export const DeleteClient = createAsyncThunk(
    'Client/Delete',
    async (dataid) => {
        let result = await fetch(process.env.REACT_APP_API_URL + "api/client/delete/" + dataid, {
            method: "DELETE"
        })
        result = await result.json();
        return result;
    });
const ClientSlice = createSlice({
    name: 'client',
    initialState: {
        clientLoading: false,
        client: null,
        clientError: null
    },
    extraReducers: (builder) => {
        builder
            .addCase(GetAllClient.pending, (state) => {
                state.clientLoading = true;
                state.client = null;
                state.clientError = null;
            })
            .addCase(GetAllClient.fulfilled, (state, action) => {
                state.clientLoading = false;
                state.client = action.payload.data;
                state.clientError = null;
            })
            .addCase(GetAllClient.rejected, (state, action) => {
                state.clientLoading = false;
                state.client = null;
                if (action.error !== undefined) {
                    if (action.error.message) {
                        state.clientError = action.error.message;
                    }
                }
            })
            .addCase(GetClient.pending, (state) => {
                state.clientLoading = true;
                state.client = null;
                state.clientError = null;
            })
            .addCase(GetClient.fulfilled, (state, action) => {
                state.clientLoading = false;
                state.client = action.payload.data;
                state.clientError = null;
            })
            .addCase(GetClient.rejected, (state, action) => {
                state.clientLoading = false;
                state.client = null;
                if (action.error !== undefined) {
                    if (action.error.message) {
                        state.clientError = action.error.message;
                    }
                }
            })
            .addCase(AddClients.pending, (state) => {
                state.clientLoading = true;
                state.client = null;
                state.clientError = null;
            })
            .addCase(AddClients.fulfilled, (state, action) => {
                state.clientLoading = false;
                state.client = action.payload.data;
                state.clientError = null;
            })
            .addCase(AddClients.rejected, (state, action) => {
                state.clientLoading = false;
                state.client = null;
                if (action.error !== undefined) {
                    if (action.error.message) {
                        state.clientError = action.error.message;
                    }
                }
            })
            .addCase(UpdateClient.pending, (state) => {
                state.clientLoading = true;
                state.client = null;
                state.clientError = null;
            })
            .addCase(UpdateClient.fulfilled, (state, action) => {
                state.clientLoading = false;
                state.client = action.payload.data;
                state.clientError = null;
            })
            .addCase(UpdateClient.rejected, (state, action) => {
                state.clientLoading = false;
                state.client = null;
                if (action.error !== undefined) {
                    if (action.error.message) {
                        state.clientError = action.error.message;
                    }
                }
            })
            .addCase(DeleteClient.pending, (state) => {
                state.clientLoading = true;
                state.client = null;
                state.clientError = null;
            })
            .addCase(DeleteClient.fulfilled, (state, action) => {
                state.clientLoading = false;
                state.client = action.payload.data;
                state.clientError = null;
            })
            .addCase(DeleteClient.rejected, (state, action) => {
                state.clientLoading = false;
                state.client = null;
                if (action.error !== undefined) {
                    if (action.error.message) {
                        state.clientError = action.error.message;
                    }
                }
            })
    }
});
export default ClientSlice.reducer;
import loadingimg from '../img/Hourglass.gif'
function Loading() {
    return (
        <>
            <div className="loading-background">
                <div className='loading-cont'>
                    <img src={loadingimg} alt='loading.gif' className="loading-img"/>
                </div>
            </div>
        </>
    );
}
export default Loading;